@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wakana-main_visual {
  background-image: url("/images/wakana/wakana-main_visual-bgimg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  opacity: 0;
  animation-name: fadeIn;
  animation-delay: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

.wakana-main_visual:before {
  content: '';
  display: block;
  padding-top: 33.67%;
}

@media (max-width: 428px) {
  .wakana-main_visual {
    height: 539px;
    background-image: url("/images/wakana/wakana-main_visual-bgimg-sp2.png");
    background-size: 855px 641px;
    background-position: 24% -97px;
  }
  .wakana-main_visual:before {
    display: none;
  }
}

.wakana-main_visual--ttl {
  position: absolute;
  top: 36%;
  left: 17.5%;
  width: 28.989%;
}

@media (max-width: 428px) {
  .wakana-main_visual--ttl {
    top: 42px;
    width: 272px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.wakana-main_visual--ttl_img {
  display: block;
  width: 100%;
}

.wakana-main_visual--ttl_img-sp {
  display: none;
}

@media (max-width: 428px) {
  .wakana-main_visual--ttl_img {
    display: none;
  }
  .wakana-main_visual--ttl_img-sp {
    display: block;
  }
}

.wakana-main_visual--note {
  position: absolute;
  font-family: "Noto Serif JP", serif;
  font-size: 14px;
  letter-spacing: 0.1em;
  right: 0;
  left: auto;
  bottom: 0;
  color: #fff;
  background-color: #4b94af;
  padding: 0.64em;
  border-radius: 1em 0 0 0;
  width: 142px;
}

.wakana-main_visual_circle {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 30.3%;
  top: 67%;
  left: calc(50% - 33%);
}

@media (max-width: 428px) {
  .wakana-main_visual_circle {
    width: 325px;
    left: calc(50% - 162.5px);
    top: auto;
    bottom: 48px;
  }
}

.wakana-main_visual_circle--item {
  width: 30%;
  opacity: 0;
  animation-name: fadeIn;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

.wakana-main_visual_circle--item:nth-child(1) {
  animation-delay: 1.5s;
}

.wakana-main_visual_circle--item:nth-child(2) {
  animation-delay: 1.75s;
}

.wakana-main_visual_circle--item:nth-child(3) {
  animation-delay: 2s;
}

.wakana-main_visual_circle--img {
  display: block;
  width: 100%;
}

.wakana-top {
  padding: 80px 0 240px;
}

@media (max-width: 428px) {
  .wakana-top {
    padding: 20px;
  }
}

.wakana-top--ttl {
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2em;
  line-height: 2.0;
  margin: 0 0 70px 0;
  color: #474843;
}

@media (max-width: 428px) {
  .wakana-top--ttl {
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }
}

.wakana-lead {
  background: url("/images/wakana/wakana-lead-bgimg.png") no-repeat center/cover;
  height: 600px;
  padding-top: 130px;
}

@media (max-width: 428px) {
  .wakana-lead {
    height: 485px;
    padding-top: 70px;
    background-position: right;
  }
}

.wakana-lead--ttl {
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 24px;
  letter-spacing: 0.2em;
  line-height: 2;
  color: #fff;
  text-align: center;
  margin-bottom: 1.5em;
}

@media (max-width: 428px) {
  .wakana-lead--ttl {
    font-size: 20px;
    letter-spacing: 0.05em;
  }
}

.wakana-lead--description {
  color: #fff;
  font-size: 16px;
  line-height: 2.375;
  text-align: center;
  margin: 0 0 60px;
}

@media (max-width: 428px) {
  .wakana-lead--description {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 35px;
  }
}

.wakana-lead--link {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 320px;
  margin: 0 auto;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 22px 0;
  position: relative;
  opacity: 1;
  transition: 0.2s;
}

.wakana-lead--link:hover {
  background-color: #fff;
  color: #333;
}

.wakana-lead--link:hover:after {
  border-color: #333 #333 transparent transparent;
}

.wakana-lead--link:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  right: 20px;
  transform: rotate(45deg);
}

.wakana-contact_area {
  background-color: #d8d8d8;
  position: relative;
  padding: 40px 0;
}

.wakana-contact_area:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  left: calc(50% - 1px);
  top: 0;
  background-color: #fff;
}

@media (max-width: 428px) {
  .wakana-contact_area {
    padding: 30px 20px;
  }
  .wakana-contact_area:before {
    display: none;
  }
}

.wakana-contact_area--inner {
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

@media (max-width: 428px) {
  .wakana-contact_area--inner {
    display: block;
  }
}

.wakana-contact_area--box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(50% - 70px);
}

@media (max-width: 428px) {
  .wakana-contact_area--box {
    width: auto;
    display: block;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
  }
  .wakana-contact_area--box:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.wakana-contact_area--lead {
  font-family: "Noto Serif JP", serif;
  font-size: 18px;
  margin: 0 0 10px;
}

@media (max-width: 428px) {
  .wakana-contact_area--lead {
    text-align: center;
    font-size: 20px;
    letter-spacing: 0.05em;
  }
}

.wakana-contact_area--tel {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  margin: 0 0 8px;
}

@media (max-width: 428px) {
  .wakana-contact_area--tel {
    text-align: center;
  }
}

.wakana-contact_area--small {
  display: block;
  font-family: "Noto Serif JP", serif;
  font-size: 14px;
  margin: 0 0 24px;
}

.wakana-contact_area--small:last-child {
  margin-bottom: 0;
}

@media (max-width: 428px) {
  .wakana-contact_area--small {
    font-size: 12px;
    text-align: center;
  }
}

.wakana-contact_area--link_flow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  height: 48px;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  font-size: 14px;
  margin-top: auto;
  color: #6692ab;
  background-color: #fff;
  border: 1px solid #6692ab;
}

.wakana-contact_area--link_flow:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  border-top: 1px solid #6692ab;
  border-right: 1px solid #6692ab;
}

.wakana-contact_area--link_flow:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-contact_area--link_flow {
    margin: 0 auto;
  }
}

.wakana-contact_area--link_book {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  height: 48px;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  font-size: 14px;
  margin-top: auto;
  color: #fff;
  background-color: #6692ab;
}

.wakana-contact_area--link_book:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.wakana-contact_area--link_book:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-contact_area--link_book {
    margin: 0 auto;
  }
}

.wakana-contact_area--annotate {
  font-family: "Noto Serif JP", serif;
  font-size: 14px;
  margin: 0 0 18px;
}

@media (max-width: 428px) {
  .wakana-contact_area--annotate {
    line-height: 1.42;
    margin-bottom: 10px;
  }
}

.wakana-contact {
  background-color: #d8d8d8;
  padding: 40px 0;
  border-top: 2px solid #fff;
}

@media (max-width: 428px) {
  .wakana-contact {
    padding: 30px 20px;
    border-top: none;
    position: relative;
  }
  .wakana-contact:before {
    position: absolute;
    content: '';
    display: block;
    width: calc(100% - 40px);
    height: 1px;
    background-color: #fff;
    left: 20px;
    top: 0;
  }
}

.wakana-contact--ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 18px;
  letter-spacing: 0.15em;
  text-align: center;
  font-weight: normal;
}

.wakana-contact--inner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 40px;
  margin-top: 20px;
}

@media (max-width: 428px) {
  .wakana-contact--inner {
    display: block;
    margin-top: 10px;
  }
}

.wakana-contact--content {
  min-width: 260px;
}

@media (max-width: 428px) {
  .wakana-contact--content {
    min-width: inherit;
  }
}

.wakana-contact--tel {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
}

@media (max-width: 428px) {
  .wakana-contact--tel {
    text-align: center;
  }
}

.wakana-contact--business_hour {
  font-family: "Noto Serif JP", serif;
  font-size: 14px;
  margin-top: 8px;
}

@media (max-width: 428px) {
  .wakana-contact--business_hour {
    text-align: center;
  }
}

.wakana-contact--link {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  height: 48px;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  font-size: 14px;
  margin-top: auto;
  background-color: #fff;
  color: #333;
}

.wakana-contact--link:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}

.wakana-contact--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-contact--link {
    margin-top: 24px;
  }
}

.wakana-products {
  padding: 90px 0 72px;
  max-width: 980px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}

@media (max-width: 428px) {
  .wakana-products {
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.wakana-products--block {
  height: 500px;
  padding-top: 124px;
  padding-left: 68px;
  margin: 0 0 32px;
}

.wakana-products--block:last-child {
  margin-bottom: 0;
}

.wakana-products--block-necklace {
  background: url("/images/wakana/wakana-products--block-necklace-bgimg.png") no-repeat center;
}

.wakana-products--block-ring {
  background: url("/images/wakana/wakana-products--block-ring-bgimg.png") no-repeat center;
  padding-top: 62px;
}

.wakana-products--block-pendant {
  background: url("/images/wakana/wakana-products--block-pendant-bgimg.png") no-repeat center;
}

@media (max-width: 428px) {
  .wakana-products--block {
    height: 600px;
    padding-top: 284px;
    padding-left: 20px;
    margin-bottom: 0;
  }
  .wakana-products--block-necklace {
    background: url("/images/wakana/wakana-products--block-necklace-bgimg-sp.png") no-repeat center/cover;
  }
  .wakana-products--block-ring {
    background: url("/images/wakana/wakana-products--block-ring-bgimg-sp.png") no-repeat center/cover;
    height: 670px;
  }
  .wakana-products--block-pendant {
    background: url("/images/wakana/wakana-products--block-pendant-bgimg-sp.png") no-repeat center/cover;
  }
}

.wakana-products--ttl {
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.75;
  margin: 0 0 40px;
}

@media (max-width: 428px) {
  .wakana-products--ttl {
    font-size: 20px;
    margin-bottom: 22px;
    letter-spacing: -0.05em;
    line-height: 1.65;
  }
}

.wakana-products--ttl-ring {
  margin-bottom: 27px;
}

.wakana-products--ttl_wakana {
  letter-spacing: 0.1em;
}

@media (max-width: 428px) {
  .wakana-products--ttl_wakana {
    font-size: 18px;
    letter-spacing: 0;
  }
}

.wakana-products--item {
  text-decoration: none;
  color: #333;
  width: 300px;
  background-image: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
  margin: 0 0 10px;
  padding-left: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.wakana-products--item:before {
  position: absolute;
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  transform: rotate(45deg);
  top: calc(50% - 4px);
  left: 15px;
  z-index: 2;
}

.wakana-products--item:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.2s;
  opacity: 0;
}

.wakana-products--item:hover:after {
  opacity: 1;
}

.wakana-products--item-ring {
  margin-bottom: 5px;
}

.wakana-products--size {
  font-size: 15px;
  position: relative;
  z-index: 2;
}

.wakana-products--small {
  font-size: 12px;
  position: relative;
  z-index: 2;
  margin-top: 6px;
}

.wakana-products--memo {
  display: block;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 14px;
  margin-top: 1.5em;
}

.wakana-size {
  max-width: 980px;
  margin: 0 auto;
}

@media (max-width: 428px) {
  .wakana-size {
    padding: 0 20px;
  }
}

.wakana-size--ttl {
  color: #fff;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  padding: 30px 0;
  background-color: #8CBDC6;
  letter-spacing: 0.1em;
  position: relative;
  cursor: pointer;
}

.wakana-size--ttl:after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(-45deg);
  top: calc(50% - 6px);
  left: calc(50% + 9em);
  transform-origin: bottom right;
  transition: 0.2s;
}

.js-show .wakana-size--ttl:after {
  transform-origin: center;
  transform: rotate(135deg);
}

@media (max-width: 428px) {
  .wakana-size--ttl {
    font-size: 16px;
  }
  .wakana-size--ttl:after {
    left: calc(50% + 8em);
  }
}

.wakana-size_content {
  padding: 35px 0;
  background-color: #8CBDC6;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, transparent 100%);
  background-position: center top;
  background-size: 100% 15px;
  background-repeat: no-repeat;
  display: none;
}

@media (max-width: 428px) {
  .wakana-size_content {
    padding: 20px 0;
    overflow-x: scroll;
  }
}

.wakana-size_content--inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 35px;
  min-height: 300px;
}

@media (max-width: 428px) {
  .wakana-size_content--inner {
    padding: 0 20px;
    width: 920px;
  }
}

.wakana-size_content--box {
  background-color: #fff;
  padding-bottom: 14px;
  min-height: 300px;
}

@media (max-width: 428px) {
  .wakana-size_content--box {
    width: 284px;
  }
  .wakana-size_content--box:last-child {
    margin-right: 0;
  }
}

.wakana-size_content--img {
  max-width: 100%;
  display: block;
  margin: 0 0 18px;
}

.wakana-size_content--lead {
  color: #3683A0;
  font-family: "Noto Serif JP", serif;
  font-size: 18px;
  text-align: center;
  margin: 0 0 8px;
}

.wakana-size_content--description {
  font-size: 14px;
  text-align: center;
  line-height: 1.75;
}

.wakana-banner {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 90px 0 48px;
}

@media (max-width: 428px) {
  .wakana-banner {
    padding: 20px;
  }
}

.wakana-banner--link {
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.2s;
  margin: 20px 0 0 0;
  width: 473px;
}

.wakana-banner--link:nth-child(-n+2) {
  margin-top: 0;
}

.wakana-banner--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-banner--link {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .wakana-banner--link:last-child {
    margin-bottom: 0;
  }
}

.wakana-banner--link_text {
  position: absolute;
  font-family: "Noto Serif JP", serif;
  font-size: 20px;
  color: #4b94af;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.wakana-banner--link_text-white {
  color: #fff;
}

@media (max-width: 428px) {
  .wakana-banner--link_text {
    font-size: 18px;
  }
}

.wakana-banner--link_img {
  max-width: 100%;
}

.wakana-faq_button {
  margin: 0 auto;
  padding: 68px 0 0;
}

@media (max-width: 428px) {
  .wakana-faq_button {
    padding: 40px 20px 0;
  }
}

.wakana-faq_button--link {
  font-family: "Noto Serif JP", serif;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  width: 368px;
  height: 68px;
  border: 1px solid;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  font-size: 18px;
  background-color: #fff;
  color: #333;
  margin: 0 auto;
}

.wakana-faq_button--link:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}

.wakana-faq_button--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-faq_button--link {
    width: 100%;
  }
}
