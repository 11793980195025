@charset "UTF-8";
@import "../mixin";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wakana-main_visual {
  background-image: url('/images/wakana/wakana-main_visual-bgimg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  opacity: 0;
  animation-name: fadeIn;
  animation-delay: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-iteration-count: 1;
  &:before {
    content: '';
    display: block;
    padding-top: 33.67%;
  }
  @include sp {
    height: 539px;
    background-image: url('/images/wakana/wakana-main_visual-bgimg-sp2.png');
    background-size: 855px 641px;
    background-position: 24% -97px;
    &:before {
      display: none;
    }
  }
  &--ttl {
    position: absolute;
    top: 36%;
    left: 17.5%;
    width: 28.989%;
    @include sp {
      top: 42px;
      width: 272px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &--ttl_img {
    display: block;
    width: 100%;
    &-sp {
      display: none;
    }
    @include sp {
      display: none;
      &-sp {
        display: block;
      }
    }
  }
  &--note {
    position: absolute;
    font-family: $ff-noto_serif;
    font-size: 14px;
    letter-spacing: 0.1em;
    right: 0;
    left: auto;
    bottom: 0;
    color: #fff;
    background-color: #4b94af;
    padding: 0.64em;
    border-radius: 1em 0 0 0;
    width: 142px;
  }
}
.wakana-main_visual_circle {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  //width: 415px;
  width: 30.3%;
  top: 67%;
  left: calc(50% - 33%);
  @include sp {
    width: 325px;
    left: calc(50% - 162.5px);
    top: auto;
    bottom: 48px;
  }
  &--item {
    width: 30%;
    opacity: 0;
    animation-name: fadeIn;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-duration: 0.5s;
    &:nth-child(1) {
      animation-delay: 1.5s;
    }
    &:nth-child(2) {
      animation-delay: 1.75s;
    }
    &:nth-child(3) {
      animation-delay: 2s;
    }
  }
  &--img {
    display: block;
    width: 100%;
  }
}

.wakana-top {
  padding: 80px 0 240px;
  @include sp {
    padding: 20px;
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.2em;
    line-height: 2.0;
    margin: 0 0 70px 0;
    color: #474843;
    @include sp {
      font-size: 20px;
      letter-spacing: 0.05em;
      margin-bottom: 20px;
    }
  }
}

.wakana-lead {
  background: url("/images/wakana/wakana-lead-bgimg.png") no-repeat center/cover;
  height: 600px;
  padding-top: 130px;
  @include sp {
    height: 485px;
    padding-top: 70px;
    background-position: right;
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0.2em;
    line-height: 2;
    color: #fff;
    text-align: center;
    margin-bottom: 1.5em;
    @include sp {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
  }
  &--description {
    color: #fff;
    font-size: 16px;
    line-height: 2.375;
    text-align: center;
    margin: 0 0 60px;
    @include sp {
      font-size: 15px;
      line-height: 2;
      margin-bottom: 35px;
    }
  }
  &--link {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    width: 320px;
    margin: 0 auto;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 22px 0;
    position: relative;
    opacity: 1;
    transition: 0.2s;
    &:hover {
      background-color: #fff;
      color: #333;
      &:after {
        border-color: #333 #333 transparent transparent;
      }
    }
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      right: 20px;
      transform: rotate(45deg);
    }
  }
}

.wakana-contact_area {
  background-color: $color-gray_d8d8d8;
  position: relative;
  padding: 40px 0;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: calc(50% - 1px);
    top: 0;
    background-color: #fff;
  }
  @include sp {
    padding: 30px 20px;
    &:before {
      display: none;
    }
  }
  &--inner {
    max-width: 850px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    @include sp {
      display: block;
    }
  }
  &--box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(50% - 70px);
    @include sp {
      width: auto;
      display: block;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #fff;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
  &--lead {
    font-family: $ff-noto_serif;
    font-size: 18px;
    margin: 0 0 10px;
    @include sp {
      text-align: center;
      font-size: 20px;
      letter-spacing: 0.05em;
    }
  }
  &--tel {
    font-family: $ff-noto_serif;
    font-size: 24px;
    margin: 0 0 8px;
    @include sp {
      text-align: center;
    }
  }
  &--small {
    display: block;
    font-family: $ff-noto_serif;
    font-size: 14px;
    margin: 0 0 24px;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp {
      font-size: 12px;
      text-align: center;
    }
  }
  &--link_flow {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    height: 48px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
    transition: 0.2s;
    font-size: 14px;
    margin-top: auto;
    color: #6692ab;
    background-color: #fff;
    border: 1px solid #6692ab;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid #6692ab;
      border-right: 1px solid #6692ab;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      margin: 0 auto;
    }
  }
  &--link_book {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    height: 48px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
    transition: 0.2s;
    font-size: 14px;
    margin-top: auto;
    color: #fff;
    background-color: #6692ab;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      margin: 0 auto;
    }
  }
  &--annotate {
    font-family: $ff-noto_serif;
    font-size: 14px;
    margin: 0 0 18px;
    @include sp {
      line-height: 1.42;
      margin-bottom: 10px;
    }
  }
}

.wakana-contact {
  background-color: #d8d8d8;
  padding: 40px 0;
  border-top: 2px solid #fff;
  @include sp {
    padding: 30px 20px;
    border-top: none;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      display: block;
      width: calc(100% - 40px);
      height: 1px;
      background-color: #fff;
      left: 20px;
      top: 0;
    }
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-size: 18px;
    letter-spacing: 0.15em;
    text-align: center;
    font-weight: normal;
  }
  &--inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 40px;
    margin-top: 20px;
    @include sp {
      display: block;
      margin-top: 10px;
    }
  }
  &--content {
    min-width: 260px;
    @include sp {
      min-width: inherit;
    }
  }
  &--tel {
    font-family: $ff-noto_serif;
    font-size: 24px;
    @include sp {
      text-align: center;
    }
  }
  &--business_hour {
    font-family: $ff-noto_serif;
    font-size: 14px;
    margin-top: 8px;
    @include sp {
      text-align: center;
    }
  }
  &--link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    height: 48px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
    transition: 0.2s;
    font-size: 14px;
    margin-top: auto;
    background-color: #fff;
    color: #333;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid #333;
      border-right: 1px solid #333;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      margin-top: 24px;
    }
  }
}

.wakana-products {
  padding: 90px 0 72px;
  max-width: 980px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  @include sp {
    padding-top: 0;
    padding-bottom: 20px;
  }
  &--block {
    height: 500px;
    padding-top: 124px;
    padding-left: 68px;
    margin: 0 0 32px;
    &:last-child {
      margin-bottom: 0;
    }
    &-necklace {
      background: url('/images/wakana/wakana-products--block-necklace-bgimg.png') no-repeat center;
    }
    &-ring {
      background: url('/images/wakana/wakana-products--block-ring-bgimg.png') no-repeat center;
      padding-top: 62px;
    }
    &-pendant {
      background: url('/images/wakana/wakana-products--block-pendant-bgimg.png') no-repeat center;
    }
    @include sp {
      height: 600px;
      padding-top: 284px;
      padding-left: 20px;
      margin-bottom: 0;
      &-necklace {
        background: url('/images/wakana/wakana-products--block-necklace-bgimg-sp.png') no-repeat center/cover;
      }
      &-ring {
        background: url('/images/wakana/wakana-products--block-ring-bgimg-sp.png') no-repeat center/cover;
        height: 670px;
      }
      &-pendant {
        background: url('/images/wakana/wakana-products--block-pendant-bgimg-sp.png') no-repeat center/cover;
      }
    }
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.75;
    margin: 0 0 40px;
    @include sp {
      font-size: 20px;
      margin-bottom: 22px;
      letter-spacing: -0.05em;
      line-height: 1.65;
    }
    &-ring {
      margin-bottom: 27px;
      @include sp {

      }
    }
  }
  &--ttl_wakana {
    letter-spacing: 0.1em;
    @include sp {
      font-size: 18px;
      letter-spacing: 0;
    }
  }
  &--list {
  }
  &--item {
    text-decoration: none;
    color: #333;
    width: 300px;
    background-image: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    background-repeat: no-repeat;
    margin: 0 0 10px;
    padding-left: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      transform: rotate(45deg);
      top: calc(50% - 4px);
      left: 15px;
      z-index: 2;
    }
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #fff;
      z-index: 1;
      transition: 0.2s;
      opacity: 0;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
    &-ring {
      margin-bottom: 5px;
    }
  }
  &--size {
    font-size: 15px;
    position: relative;
    z-index: 2;
  }
  &--small {
    font-size: 12px;
    position: relative;
    z-index: 2;
    margin-top: 6px;
  }
  &--memo {
    display: block;
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 14px;
    margin-top: 1.5em;
  }
}

.wakana-size {
  max-width: 980px;
  margin: 0 auto;
  @include sp {
    padding: 0 20px;
    &-index {
    }
  }
  &--ttl {
    color: #fff;
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    padding: 30px 0;
    background-color: #8CBDC6;
    letter-spacing: 0.1em;
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(-45deg);
      top: calc(50% - 6px);
      left: calc(50% + 9em);
      transform-origin: bottom right;
      transition: 0.2s;
      .js-show & {
        transform-origin: center;
        transform: rotate(135deg);
      }
    }
    @include sp {
      font-size: 16px;
      &:after {
        left: calc(50% + 8em);
      }
    }
  }
}
.wakana-size_content {
  padding: 35px 0;
  background-color: #8CBDC6;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, transparent 100%);
  background-position: center top;
  background-size: 100% 15px;
  background-repeat: no-repeat;
  display: none;
  .js-show & {
  }
  @include sp {
    padding: 20px 0;
    overflow-x: scroll;
  }
  &--inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 35px;
    min-height: 300px;
    @include sp {
      padding: 0 20px;
      width: 920px;
    }
  }
  &--box {
    background-color: #fff;
    padding-bottom: 14px;
    min-height: 300px;
    @include sp {
      width: 284px;
      //margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &--img {
    max-width: 100%;
    display: block;
    margin: 0 0 18px;
  }
  &--lead {
    color: #3683A0;
    font-family: $ff-noto_serif;
    font-size: 18px;
    text-align: center;
    margin: 0 0 8px;
  }
  &--description {
    font-size: 14px;
    text-align: center;
    line-height: 1.75;
  }
}

.wakana-banner {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 90px 0 48px;
  @include sp {
    padding: 20px;
  }
  &--link {
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 0.2s;
    margin: 20px 0 0 0;
    width: 473px;
    &:nth-child(-n+2) {
      margin-top: 0;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      margin-top: 0;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--link_text {
    position: absolute;
    font-family: $ff-noto_serif;
    font-size: 20px;
    color: #4b94af;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    &-white {
      color: #fff;
    }
    @include sp {
      font-size: 18px;
    }
  }
  &--link_img {
    max-width: 100%;
  }
}

.wakana-faq_button {
  margin: 0 auto;
  padding: 68px 0 0;
  @include sp {
    padding: 40px 20px 0;
  }
  &--link {
    font-family: $ff-noto_serif;
    letter-spacing: 0.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    width: 368px;
    height: 68px;
    border: 1px solid;
    text-decoration: none;
    position: relative;
    transition: 0.2s;
    font-size: 18px;
    background-color: #fff;
    color: #333;
    margin: 0 auto;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid #333;
      border-right: 1px solid #333;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      width: 100%;
    }
  }
}